<template>
  <div ref="banScroll" class="coupon-page">
    <div class="imgdiv" :style="'background-image:url('+ data.poster_url +')'">
      <!-- <img :src="data.poster_url" alt=""> -->
    </div>
    <div class="cont">
      <div v-for="(item,i) in data.coupon_list" :key="i">
        <div class="outer" v-if="item.is_invalid == 1">
          <div class="namebox">
            <div class="name">{{ item.title }}</div>
            <div class="subname">{{ item.subtitle }}</div>
            <div class="split"></div>
            <div class="tad">
              <div class="time">{{ item.term_validity }}</div>
              <div class="opt" 
                  v-if="data.coupon_list.length > 1"
                  @click="openContent(i)">优惠券详情<van-icon v-if="i==curOpen" name="arrow-up" /><van-icon v-if="i!=curOpen" name="arrow-down" />
              </div>
            </div>
          </div>
          <div class="infobox" v-if="i==curOpen">
            <div v-for="(item2,i2) in item.description" :key="i2">
              <div class="tit">{{ item2.title }}</div>
              <div class="titcont">{{ item2.content }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="btntip" v-if="showScrollTip">上滑查看优惠券详情</div>
    <div v-if="data.status==1" @click="partake" class="btnget">立即领取</div>
    <div v-else class="btnget">活动结束</div>

    <!-- 活动结束 -->
    <div v-if="showErrModal" class="dialogbox" >
      <div class="mask"></div>
      <div class="dialog-flex">
        <div class="dialog-cont">
          <span @click="showErrModal=false" class="dclose"></span>
          <div class="tit">提示</div>
          <div class="p">
            {{ currentErrTip }}  <br> 请到好利来小程序查看更多优惠
          </div>
          <div class="minigram-btn">
            <wx-open-launch-weapp
                id="launch-btn"
                :username="config.XCX_USER_NAME"
                :path="config.XCX_PATH"
                @launch="launchHandle"
                @error="errorHandle"
            >
                <script type="text/wxtag-template">
                    <style>.btn { border:0 none; width:258px;height:30px;background:#CB986D; border-radius:15px;display: flex;align-items: center; justify-content: center;color: #fff;margin: 0 auto;font-size: 16px; }</style>
                    <button class="btn">打开好利来小程序</button>
                </script>
            </wx-open-launch-weapp>
          </div>
          <!-- <div class="btn">打开好利来小程序</div> -->
        </div>
      </div>
    </div>

    <!-- 抢光了 -->
    <!-- <div class="dialogbox" v-if="showCouponNoneModal">
      <div class="mask"></div>
      <div class="dialog-flex">
        <div class="dialog-cont">
          <span @click="showCouponNoneModal=false" class="dclose"></span>
          <div class="tit">提示</div>
          <div class="p">
            抱歉，优惠券已被抢光  <br> 请到好利来小程序查看更多优惠
          </div>
          <div class="minigram-btn">
            <wx-open-launch-weapp
                id="launch-btn"
                :username="config.XCX_USER_NAME"
                :path="config.XCX_PATH"
                @launch="launchHandle"
                @error="errorHandle"
            >
                <script type="text/wxtag-template">
                    <style>.btn {border:0 none; width:258px;height:30px;background:#CB986D; border-radius:15px;display: flex;align-items: center; justify-content: center;color: #fff;margin: 0 auto;font-size: 16px; }</style>
                    <button class="btn">打开好利来小程序</button>
                </script>
            </wx-open-launch-weapp>
          </div>
        </div>
      </div>
    </div> -->

    <!-- 成功 -->
    <div class="dialogbox" v-if="showSuccModal">
      <div class="mask"></div>
      <div class="dialog-flex">
        <div class="dialog-cont">
          <span @click="showSuccModal=false" class="dclose"></span>
          <div class="tit">领取成功</div>
          <div class="p">
            优惠券领取成功 <br> 请到「我的」-「优惠券」中查看
          </div>
          <div class="minigram-btn">
            <wx-open-launch-weapp
                id="launch-btn"
                :username="config.XCX_USER_NAME"
                :path="config.XCX_PATH"
                @launch="launchHandle"
                @error="errorHandle"
            >
                <script type="text/wxtag-template">
                    <style>.btn {border:0 none; width:258px;height:30px;background:#CB986D; border-radius:15px;display: flex;align-items: center; justify-content: center;color: #fff;margin: 0 auto;font-size: 16px; }</style>
                    <button class="btn">打开好利来小程序</button>
                </script>
            </wx-open-launch-weapp>
          </div>
          <!-- <div class="btn">去小程序使用</div> -->
        </div>
      </div>
    </div>
    <!-- 分享提示 -->
    <div class="sharetip" v-if="showSuccModal">分享好友送Ta优惠券</div>
  </div>
</template>

<script>
import wx from 'weixin-js-sdk'
import ajax from '@/utils/ajax.js'
import config from '@/utils/config'
export default {
  components: {
  },
  data() {
    return {
      config,
      id: '',
      showScrollTip: true,
      showTimeEndModal: false,
      showCouponNoneModal: false,
      showSuccModal: false,
      clientH: '',
      scrollH: '',
      timer: '',

      data: {},
      curOpen: 0,
      currentErrTip: '',
      showErrModal: false
    }
  },
  created() {
    //test
    // localStorage.setItem('accessToken', '6eba627bb4a4af1e731b9de2f3fe0d5d')
    localStorage.setItem('backpagefullpath', encodeURIComponent(this.$route.fullPath))
  },
  mounted() {
    this.id = this.$route.query.id
    let token = localStorage.getItem('accessToken')

    if(!token) {
      this.$router.push({path: '/wechat_login'})
      return
    }

    this.getDetail()
  },
  destroyed() {
    window.removeEventListener('scroll', this.handleScroll)
  },
  methods: {
    launchHandle(e) {
      console.log('launchHandle', e)
    },
    errorHandle(e) {
      console.log('errorHandle', e)
    },
    async getDetail() {
      const params = {
        event_id: this.id
      }
      const {data, code} = await ajax.post('/CouponEvent/detail', params)
      // console.log('postlogin', data, code)
      if(code == 0) {
        this.data = data
        this.data.coupon_list = this.data.coupon_list.filter((el)=>{
          return el.is_invalid == 1
        })
        if(this.data.coupon_list.length > 1) {
          this.curOpen = -1
        }
        this.shareFn()
        // this.checkTime()
        this.$nextTick(() => {
          this.clientH = window.innerHeight || document.documentElement.clientHeight
          window.addEventListener('scroll', this.handleScroll)
        })
      }
    },
    async partake() {
      const params = {
        event_id: this.id
      }
      const {code, message} = await ajax.post('/CouponEvent/partake', params)
      if(code == 0) {
        this.data.is_partake = 2
        this.showSuccModal = true
      }else {
        this.currentErrTip = message
        this.showErrModal = true
      }
    },
    openContent(index) {
      if(this.curOpen == index) {
        this.curOpen = -1
      }else{
        this.curOpen = index
      }
    },
    // checkTime() {
    //   let now = new Date().getTime()
    //   let end_time = new Date(this.data.end_time.replace(/-/g,'/')).getTime()
    //   if(end_time - now < 0) {
    //     this.showTimeEndModal = true
    //   }
    // },
    handleScroll() {
      if(!this.showScrollTip) return

      if (this.timer) {
        clearTimeout(this.timer)
        this.timer = null
      }
      this.timer = setTimeout(() => {
        this.scrollH = document.body.scrollHeight || document.documentElement.scrollHeight
        let scrollTopNum = document.body.scrollTop || document.documentElement.scrollTop

        if(scrollTopNum > 100) {
          this.showScrollTip = false
          clearTimeout(this.timer)
          this.timer = null
        }
      }, 8)
    },
    async shareFn() {
      const params = {
        url: window.location.href
      }
      const {data, code} = await ajax.post('/JsSdk/getH5SignPackage', params)
      if(code == 0) {
        wx.config({
          debug: false,
          appId: data.app_id,
          timestamp: data.timestamp,
          nonceStr: data.nonce_str,
          signature: data.signature,
          jsApiList: ['updateAppMessageShareData','updateTimelineShareData'],
          openTagList: ['wx-open-launch-weapp']
        })
        
        wx.ready(() => {
            //分享朋友
            wx.updateAppMessageShareData({
                title: this.data.share_title || this.data.name, // 分享标题
                desc: this.data.share_describe, // 分享描述
                link: location.href, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
                imgUrl: this.data.thumbnail_url || config.SHARE_IMG_URL, // 分享图标
                success: function () {
                    console.info("成功")
                    // 设置成功
                },
                fail: function (erres) {
                    console.info('失败：', erres)
                }
            })
            //分享到 朋友圈
            wx.updateTimelineShareData({
                title: this.data.share_title || this.data.name, // 分享标题
                link: location.href, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
                imgUrl: this.data.thumbnail_url || config.SHARE_IMG_URL, // 分享图标
                success: function () {
                    console.info("成功")
                    // 设置成功
                },
                fail: function (erres) {
                    console.info('失败：', erres)
                }
            })
        })
        wx.error(function(e) {
          console.log('weixin error', e)
        })
      }
    }
  }
}
</script>

<style lang="scss">

.coupon-page {
  background: #E6DEDB;
  color: #858585;
  font-size: rem(12);
  .imgdiv {
    height: 100vh;
    width: 100vw;
    background-repeat: no-repeat;
    background-size: auto 100%;
    background-position: center center;
    overflow: hidden;
    img {
      // width: 100vw;
      height: 100vh;
    }
  }
  .cont {
    padding: rem(15) rem(15) rem(70);
    .namebox {
      padding: rem(20) rem(24);
      margin-bottom: rem(14);
      background: url('../../../assets/images/bgcoupon.png') no-repeat;
      background-size: 100% 100%;
      .name {
        font-size: rem(16);
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #000000;
        line-height: rem(22);
      }
      .split {
        height: 0;
        border: rem(1) dashed #E6DEDB;
        margin: rem(14) 0;
      }
      .tad {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
    }
    .infobox {
      border-radius: rem(20);
      background: #F2EEED;
      padding: rem(23);
      margin-bottom: rem(20);
      .tit {
        font-size: rem(14);
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #000;
        line-height: rem(20);
      }
      .titcont {
        margin: rem(6) 0 rem(14);
      }
    }
  }
  .btnget {
    width: rem(257);
    height: rem(30);
    background: #CB986D;
    border-radius: rem(15);
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-size: rem(16);
    position: fixed;
    left: rem(55);
    bottom: rem(30);
  }
  .btntip {
    width: rem(140);
    height: rem(28);
    background: #333;
    opacity: 0.8;
    border-radius: rem(15);
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-size: rem(12);
    position: fixed;
    left: rem(118);
    bottom: rem(75);
  }
  .dialog-cont {
    width: rem(307);
    padding: rem(20) rem(25) rem(30);
    background: #FFFFFF;
    border-radius: rem(25);
    font-size: rem(14);
    color: #4D4D4D;
    text-align: center;
    position: relative;
    .dclose {
      position: absolute;
      right: rem(14);
      top: rem(14);
      width: rem(30);
      height: rem(30);
      background: url('../../../assets/images/bgclose.png') no-repeat;
      background-size: 100% auto;
    }
    .tit {
      font-size: rem(18);
      font-weight: 500;
      color: #000000;
    }
    .p {
      padding: rem(15) 0;
      line-height: rem(20);
    }
    .minigram-btn{
      width: 100%;
      height: rem(30);
      background: #CB986D;
      border-radius: rem(15);
    }
    .btn {
      width: rem(258);
      height: rem(30);
      background: #CB986D;
      border-radius: rem(15);
      display: flex;
      align-items: center;
      justify-content: center;
      color: #fff;
      font-size: rem(16);
      margin: 0 auto;
    }
  }
  .sharetip {
    font-size: rem(14);
    font-family: PingFangSC-Regular, PingFang SC;
    color: #FFFFFF;
    position: fixed;
    right: rem(10);
    top: rem(50);
    padding: rem(6) rem(20);
    border-radius: rem(15);
    background: rgba(#333333, 0.8);
    z-index: 888888;
  }
}
</style>